<template>
  <footer :id="FOOTER_ELEMENT_ID" class="footer">
    <div class="container">
      <div class="footer-links">
        <div class="friendlinks">
          <template v-for="(link, index) in appOptions?.friend_links || []" :key="index">
            <divider type="vertical" size="lg" v-if="index !== 0" />
            <a :href="link.value" class="item" target="_blank" rel="external nofollow noopener">
              {{ link.name }}
            </a>
          </template>
        </div>
        <div class="speciallinks">
          <template v-for="(item, index) in SPECIAL_LINKS" :key="index">
            <divider type="vertical" size="lg" v-if="index !== 0" />
            <a :href="item.url" class="item" target="_blank" rel="external nofollow noopener">
              {{ item.name }}
            </a>
          </template>
        </div>
      </div>
    </div>
    <div class="container">
      <ulink class="sitemap-btn" :href="VALUABLE_LINKS.SITE_MAP">sitemap.xml</ulink>
      <divider type="vertical" />
      <i18n zh="©2022 站根互联  " en="Powered By " />
      <ulink class="item" :href="VALUABLE_LINKS.BEIAN_MIIT">粤ICP备2021155476号-2</ulink>
      <i18n zh="、" en=", " />
      <i18n zh=" 和 日月星辰 强力驱动 行针步线" en=" and solitarily" />
      <!--
      <divider type="vertical" />
      <a class="statement" href="javascript:void(0)" @click="handleStatementModal">
        <i18n zh="周知" en="FAQ" />
      </a> -->
    </div>
  </footer>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { RouteName } from '/@/app/router'
  // import { GAEventCategories } from '/@/constants/gtag'
  import { FOOTER_ELEMENT_ID } from '/@/constants/anchor'
  import { useMetaStore } from '/@/stores/meta'
  import { getPageRoute } from '/@/transforms/route'
  import { VALUABLE_LINKS, SPECIAL_LINKS } from '/@/config/app.config'

  export default defineComponent({
    name: 'DesktopFooter',
    setup() {
      const { globalState } = useEnhancer()
      const metaStore = useMetaStore()
      const appOptions = computed(() => metaStore.appOptions.data)
      const handleStatementModal = () => {
        globalState.toggleSwitcher('statement', true)
        // gtag?.event('statement_modal', {
        //   event_category: GAEventCategories.Universal
        // })
      }

      return {
        FOOTER_ELEMENT_ID,
        VALUABLE_LINKS,
        SPECIAL_LINKS,
        appOptions,
        aboutPageUrl: getPageRoute(RouteName.About),
        handleStatementModal
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include common-bg-module();
    .container {
      color: $text-secondary;
      font-size: $font-size-h6;
      text-align: center;
      text-transform: uppercase;

      a {
        border-bottom: 1px solid transparent;
        &:hover {
          border-color: initial;
        }
      }
    }
  }
  .footer-links {
    display: flex;
    justify-content: space-between;
    margin-bottom: $gap;
    padding: 2rem;
    @include common-bg-module();
    @include radius-box($lg-radius);

    .item {
      font-weight: bold;
      border-bottom: 1px solid;
    }

    .speciallinks {
      .item {
        color: $text-disabled;
        &:hover {
          color: $text;
        }
      }
    }
  }
</style>
